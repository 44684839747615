<template lang="pug">
.absolute.bottom-5.top-5.left-10.ml-12.z-30.bg-white.shadow.grid.grid-cols-2.pr-4.pl-6.pt-5.w-module.mailing.rounded-xl(
  style='grid-template-rows: auto 1fr'
)
  .text-darkblue.col-start-1.col-end-2.text-xl.font-bold.whitespace-no-wrap.font-SourceSansPro {{ title === 1 ? $t("mailing.create") : $t("mailing.edit") }}
  .flex.justify-end.col-start-2.items-center
    img.mr-5(
      src='../../assets/icons/history.svg',
      style='width: 18px',
      v-if='notRole && title !== 1',
      @click='$emit("show-history", { objects: "mailings", objectId: mailing.id })'
    )
    i.el-icon-close.text-2xl.cursor-pointer.text-darkblue.font-semibold(
      @click='$emit("close")'
    )
  .grid.col-start-1.col-end-4.overflow-y-auto.relative(
    style='grid-template-rows: repeat(9, auto) 1fr'
  )
    .flex.mt-4.relative
      el-input(
        v-model='mailing.name',
        :class='{ "input-error": isNameMissing }',
        :placeholder='$t("mailing.mailing")',
        type='text',
        class='w-2/3'
      )
      skif-button.ml-6(v-if='!admin', width='24', @click='createOrUpdate') {{ title === 1 ? $t("create") : $t("change") }}
      transition(name='el-zoom-in-top')
        .text-notify.text-xs.top-full.left-0.absolute(v-if='isNameMissing') {{ $t("mailing.enterName") }}
    .flex.flex-col.mt-4
      span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold {{ $t("mailing.template") }}
      el-select.mt-4(
        v-if='!admin',
        type='text',
        :class='"w-2/3"',
        v-model='reportTemplateId',
        filterable
      )
        el-option.text-ellipsis(
          style='max-width: 272px',
          v-for='item in templates',
          :key='item.id',
          :label='item.name',
          :value='item.id'
        )
      el-select.mt-4(
        v-else,
        type='text',
        :class='"w-2/3"',
        v-model='mailing.report_template.name',
        filterable,
        disabled
      )
        el-option.text-ellipsis(
          style='max-width: 272px',
          v-for='item in templates',
          :key='item.id',
          :label='item.name',
          :value='item.id'
        )
    .flex.mt-4.relative
      span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold {{ $t("mailing.vehicle") }}

      .flex.cursor-pointer.fixed(v-if='isOnlyUnits', style='left: 240px')
        svg.ml-4(
          @click.stop='isShowAllUnits = !isShowAllUnits',
          width='20',
          height='20',
          viewBox='0 0 18 18',
          fill='none',
          xmlns='http://www.w3.org/2000/svg'
        )
          path(
            fill-rule='evenodd',
            clip-rule='evenodd',
            d='M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z',
            :fill='!showAllUnits ? "#F56C6C" : "#5477A9"'
          )
          path(
            fill-rule='evenodd',
            clip-rule='evenodd',
            d='M13.3446 7.18139C13.3196 7.18139 13.2957 7.17718 13.2715 7.17467L4.64671 7.18287V7.18036C4.28874 7.17592 4 6.91396 4 6.59065C4 6.26437 4.29341 6 4.65558 6C4.66227 6 4.66846 6.00148 4.67516 6.00182H13.3248C13.3315 6.00171 13.3377 6 13.3444 6C13.7066 6 14 6.26435 14 6.59065C14.0001 6.91692 13.7067 7.18139 13.3446 7.18139ZM6.17718 8.74201C6.18387 8.74201 6.19007 8.74349 6.19676 8.74383H11.8031C11.8098 8.74349 11.816 8.74201 11.8227 8.74201C12.1848 8.74201 12.4783 9.00636 12.4783 9.33265C12.4783 9.65893 12.1848 9.9233 11.8227 9.9233C11.7977 9.9233 11.7738 9.9192 11.7495 9.91659L6.16809 9.9249V9.92239C5.81013 9.91795 5.52138 9.65599 5.52138 9.33256C5.52138 9.00649 5.81526 8.74201 6.17718 8.74201ZM7.9163 11.4839C7.923 11.4839 7.92919 11.4855 7.93589 11.4857H10.0642C10.0709 11.4856 10.0771 11.4839 10.0838 11.4839C10.4459 11.4839 10.7394 11.7485 10.7394 12.0745C10.7394 12.4008 10.4459 12.6652 10.0838 12.6652C10.0588 12.6652 10.0349 12.661 10.0106 12.6585L7.90744 12.6667V12.6642C7.54947 12.6597 7.26073 12.3978 7.26073 12.0743C7.26073 11.7485 7.55426 11.4839 7.9163 11.4839Z',
            :fill='!showAllUnits ? "#F56C6C" : "#5477A9"'
          )
        transition(name='slide-fade', mode='out-in')
          .absolute.bg-white.px-2.py-4.pl-3(
            v-if='isShowAllUnits',
            style='left: 20px; border: 1px solid #cfdbeb; bottom: 25px; width: 320px; border-radius: 5px'
          )
            .text-darkblue.font-SourceSansPro.text-sm.font-semibold
              .flex.mb-2
                svg.mt-2.mr-4(
                  width='20',
                  height='20',
                  viewBox='0 0 18 18',
                  fill='none',
                  xmlns='http://www.w3.org/2000/svg'
                )
                  path(
                    fill-rule='evenodd',
                    clip-rule='evenodd',
                    d='M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z',
                    fill='#5477A9'
                  )
                  path(
                    fill-rule='evenodd',
                    clip-rule='evenodd',
                    d='M13.3446 7.18139C13.3196 7.18139 13.2957 7.17718 13.2715 7.17467L4.64671 7.18287V7.18036C4.28874 7.17592 4 6.91396 4 6.59065C4 6.26437 4.29341 6 4.65558 6C4.66227 6 4.66846 6.00148 4.67516 6.00182H13.3248C13.3315 6.00171 13.3377 6 13.3444 6C13.7066 6 14 6.26435 14 6.59065C14.0001 6.91692 13.7067 7.18139 13.3446 7.18139ZM6.17718 8.74201C6.18387 8.74201 6.19007 8.74349 6.19676 8.74383H11.8031C11.8098 8.74349 11.816 8.74201 11.8227 8.74201C12.1848 8.74201 12.4783 9.00636 12.4783 9.33265C12.4783 9.65893 12.1848 9.9233 11.8227 9.9233C11.7977 9.9233 11.7738 9.9192 11.7495 9.91659L6.16809 9.9249V9.92239C5.81013 9.91795 5.52138 9.65599 5.52138 9.33256C5.52138 9.00649 5.81526 8.74201 6.17718 8.74201ZM7.9163 11.4839C7.923 11.4839 7.92919 11.4855 7.93589 11.4857H10.0642C10.0709 11.4856 10.0771 11.4839 10.0838 11.4839C10.4459 11.4839 10.7394 11.7485 10.7394 12.0745C10.7394 12.4008 10.4459 12.6652 10.0838 12.6652C10.0588 12.6652 10.0349 12.661 10.0106 12.6585L7.90744 12.6667V12.6642C7.54947 12.6597 7.26073 12.3978 7.26073 12.0743C7.26073 11.7485 7.55426 11.4839 7.9163 11.4839Z',
                    fill='#5477A9'
                  )
                .flex.flex-col
                  span Применена фильтрация из Мониторинга.
                  span Отображаются не все объекты.
              .flex.items-center
                skif-checkbox.mr-4.ml-1(
                  v-model='showAllUnits',
                  @change='toggleAllUnits'
                )
                span.text-darkblue.font-SourceSansPro.text-sm.font-semibold Показать все объекты
    .flex.flex-col
      .flex.w-full.items-center
        el-select.mt-4(
          v-if='!admin && unitOrUnitsgroup === "unit"',
          type='text',
          v-model='unitId',
          filterable,
          :class='({ "input-error": isUnitOrUnitGroupMissing }, "w-2/3")'
        )
          el-option.text-ellipsis(
            style='max-width: 272px',
            v-for='item in currentUnits',
            :key='item.id',
            :label='item.name',
            :value='item.id'
          )
        el-select.mt-4(
          v-if='admin && unitOrUnitsgroup === "unit"',
          disabled,
          type='text',
          v-model='mailing.unit.name',
          filterable,
          :class='({ "input-error": isUnitOrUnitGroupMissing }, "w-2/3")'
        )
          el-option.text-ellipsis(
            style='max-width: 272px',
            v-for='item in mailing.unit',
            :key='item.id',
            :label='item.name',
            :value='item.id'
          )

      el-select.mt-4(
        v-if='!admin && unitOrUnitsgroup === "group"',
        type='text',
        v-model='unitsgroupId',
        filterable,
        :class='({ "input-error": isUnitOrUnitGroupMissing }, "w-2/3")'
      )
        el-option.text-ellipsis(
          style='max-width: 272px',
          v-for='item in unitsgroups',
          :key='item.id',
          :label='item.name',
          :value='item.id'
        )
      el-select.mt-4(
        v-if='admin && unitOrUnitsgroup === "group"',
        type='text',
        v-model='mailing.units_group.name',
        filterable,
        :class='({ "input-error": isUnitOrUnitGroupMissing }, "w-2/3")'
      )
        el-option.text-ellipsis(
          style='max-width: 272px',
          v-for='item in unitsgroups',
          :key='item.id',
          :label='item.name',
          :value='item.id'
        )
      transition(name='el-zoom-in-top')
        .text-notify.text-xs.top-full.left-0.absolute(
          v-if='isUnitOrUnitGroupMissing'
        ) {{ unitOrUnitsgroup === "unit" ? $t("mailing.chooseObject") : $t("mailing.chooseGroup") }}
    .flex.mt-4.justify-between(:class='"w-3/5"')
      skif-radio(
        v-model='unitOrUnitsgroup',
        width='20',
        name='object',
        label='unit'
      ) {{ $t("mailing.object") }}
      skif-radio.ml-8(
        v-model='unitOrUnitsgroup',
        width='40',
        name='object',
        label='group'
      ) {{ $t("mailing.objectGroup") }}
    .flex.flex-col.mt-4(:class='"w-2/3"')
      span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold {{ $t("mailing.periodicity") }}
      .flex.mt-4.relative
        skif-input-number(
          width='24',
          v-model.number='mailing.periodic',
          :error='isPeriodicMissing'
        )
        el-select.ml-4(
          type='text',
          v-model='mailing.periodic_type',
          filterable
        )
          el-option.text-ellipsis(
            style='max-width: 272px',
            v-for='item in periodicType',
            :key='item.key',
            :label='item.value',
            :value='item'
          )
        transition(name='el-zoom-in-top')
          .text-notify.text-xs.top-full.left-0.absolute(
            v-if='isPeriodicMissing'
          ) {{ $t("mailing.periodicity.warning") }}
    .flex.flex-col.mt-4.relative(:class='"w-2/3"')
      span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold {{ $t("mailing.daysweek") }}
      el-select.mt-4(
        type='text',
        v-model='mailing.weekdays',
        multiple,
        :class='{ "input-error": isWeekdaysMissing }',
        filterable,
        :disabled='admin'
      )
        el-option.text-ellipsis(
          style='max-width: 272px',
          v-for='item in weekdaysType',
          :key='item.key',
          :label='item.value',
          :value='item'
        )
      transition(name='el-zoom-in-top')
        .text-notify.text-xs.top-full.left-0.absolute(v-if='isWeekdaysMissing') {{ $t("mailing.daysweek.warning") }}
    .flex.flex-col.mt-4.relative(:class='"w-2/3"')
      span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold {{ $t("mailing.adresses") }}
      el-select.mt-4(
        :disabled='admin',
        type='text',
        multiple,
        v-model='mailing.delivery_emails',
        :class='{ "input-error": isDeliveryEmailsMissing }',
        filterable
      )
        el-option.text-ellipsis(
          style='max-width: 272px',
          v-for='item in users',
          :key='item.id',
          :label='item.email',
          :value='item.email'
        )
          span.email-name {{item.name}}
          span.email-icon(v-if="item.is_approved")
            EmailIcon
          span.email-icon(v-else)
            UnverfiedIcon(:color="item.is_approved ? 'normal' : 'danger'")
      transition(name='el-zoom-in-top')
        .text-notify.text-xs.top-full.left-0.absolute(v-if='isWeekdaysMissing') {{ $t("mailing.adresses.warning") }}
    .flex.flex-col.mt-4.relative(:class='"w-2/3"')
      span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold {{ $t("mailing.subject") }}
      .flex.flex-col.relative.justify-center
        el-input.mt-4(
          type='textarea',
          v-model='mailing.msg',
          :class='{ "input-error": isMsgMissing }'
        )
        transition(name='el-zoom-in-top')
          .text-notify.text-xs.top-full.left-0.absolute(v-if='isMsgMissing') {{ $t("mailing.subject.warning") }}
    .flex.flex-col.mt-4(:class='"w-2/3"')
      span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold {{ $t("mailing.timeDateSent") }}
      el-date-picker.mt-4(
        clearable,
        :picker-options='pickerOptions',
        popper-class='reportsDatepicker',
        type='datetime',
        v-model='mailing.last_run',
        :format='dateTimeFormat',
        value-format='yyyy-MM-dd HH:mm:ss'
      )
      .flex.flex-col.my-3(:class='"w-2/3"')
        span.text-annotationColor.font-SourceSansPro.text-sm.font-semibold {{ $t("mailing.isActive") }}
        skif-switch.mt-4(v-model='mailing.is_active')
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  components: {
    EmailIcon: () => import('../notifications/icons/EmailIcon.vue'),
    UnverfiedIcon: () => import('../notifications/icons/UnverfiedIcon.vue')
  },
  props: {
    title: Number,
    mailing: {
      type: Object,
      default: () => {
        return {
          name: '',
          unit: {
            id: ''
          },
          units_group: {
            id: ''
          },
          report_template: {
            id: ''
          },
          periodic: '',
          periodic_type: {},
          weekdays: [],
          delivery_emails: [],
          last_run: '',
          msg: '',
          is_active: false
        }
      }
    },
    admin: Boolean
  },
  data() {
    return {
      showAllUnits: false,
      isOnlyUnits: false,
      isShowAllUnits: false,

      pickerOptions: {
        firstDayOfWeek: 1
      },
      unitOrUnitsgroup: 'unit',
      unitId: '',
      unitsgroupId: '',
      reportTemplateId: '',
      // validation
      isNameMissing: false,
      isPeriodicMissing: false,
      isWeekdaysMissing: false,
      isDeliveryEmailsMissing: false,
      isMsgMissing: false,
      isUnitOrUnitGroupMissing: false,
      dateFormat: '',
      timeFormat: '',
      dateTimeFormat: '',
      currentUnits: null,
      customUnits: []
    }
  },
  computed: {
    ...mapGetters('login', ['role', 'notRole']),
    ...mapState('users', {
      users: (state) => state.users
    }),
    ...mapState('dictionary', {
      periodicType: (state) => state.periodic_type,
      weekdaysType: (state) => state.weekdays_type
    }),
    ...mapState('reports', {
      templates: (state) => state.templates
    }),
    ...mapGetters('reports', ['defaultReportTemplate']),
    ...mapState('units', {
      units: (state) => state.defaultUnits,
      unitsgroups: (state) => state.unitsGroups
    }),
    ...mapState('login', {
      me: (state) => state.me
    })
  },
  watch: {
    'mailing.name': function (val) {
      this.isNameMissing = val === ''
    },
    'mailing.periodic': function (val) {
      this.isPeriodicMissing = val === ''
    },
    'mailing.weekdays': function (val) {
      this.isWeekdaysMissing = !val.length
    },
    'mailing.delivery_emails': function (val) {
      this.isDeliveryEmailsMissing = !val.length
    },
    'mailing.msg': function (val) {
      this.isMsgMissing = val === ''
    },
    unitId(val) {
      this.isUnitOrUnitGroupMissing = val === ''
    },
    unitsgroupId(val) {
      this.isUnitOrUnitGroupMissing = val === ''
    },
    unitOrUnitsgroup(val) {
      this.isUnitOrUnitGroupMissing = false
    }
  },
  methods: {
    toggleAllUnits(val) {
      console.log('toggle unit')
      if (this.showAllUnits) {
        this.currentUnits = this.units
        this.unitId = this.currentUnits[0].id
      } else if (this.customUnits.length) {
        this.currentUnits = this.customUnits
        this.unitId = this.customUnits[0].id
      } else {
        this.currentUnits = this.units
      }
    },
    valid() {
      let error = true
      if (this.mailing.name === '') {
        this.isNameMissing = !this.mailing.name
        error = false
      }
      if (this.mailing.periodic === '') {
        this.isPeriodicMissing = this.mailing.periodic === ''
        error = false
      }
      if (!this.mailing.weekdays.length) {
        this.isWeekdaysMissing = !this.mailing.weekdays.length
        error = false
      }
      if (!this.mailing.delivery_emails.length) {
        this.isDeliveryEmailsMissing = !this.mailing.delivery_emails.length
        error = false
      }
      if (this.mailing.msg === '') {
        this.isMsgMissing = this.mailing.msg === ''
        error = false
      }
      if (this.unitOrUnitsgroup === 'unit' && this.unitId === '') {
        this.isUnitOrUnitGroupMissing = this.unitId === ''
        error = false
      } else if (
        this.unitOrUnitsgroup === 'group' &&
        this.unitsgroupId === ''
      ) {
        this.isUnitOrUnitGroupMissing = this.unitsgroupId === ''
        error = false
      }
      return error
    },
    createOrUpdate() {
      if (this.valid()) {
        if (this.unitOrUnitsgroup === 'unit') {
          this.mailing.unit = { id: this.unitId }
          delete this.mailing.units_group
        } else {
          this.mailing.units_group = { id: this.unitsgroupId }
          delete this.mailing.unit
        }
        this.mailing.report_template.id = this.reportTemplateId
        this.title === 1
          ? this.$store
              .dispatch('mailings/CREATE', this.mailing)
              .then((_) => this.$emit('close'))
          : this.$store
              .dispatch('mailings/UPDATE', this.mailing)
              .then((_) => this.$emit('close'))
      }
    }
  },
  created() {
    if (this.title === 1) {
      this.reportTemplateId = this.defaultReportTemplate.id
      this.unitId = this.units.length ? this.units[0].id : ''
      this.unitsgroupId = this.unitsgroups.length ? this.unitsgroups[0].id : ''
      this.mailing.periodic_type = this.periodicType[0]
    } else {
      if (this.mailing.unit) {
        this.unitId = this.mailing.unit.id
        this.unitOrUnitsgroup = 'unit'
        this.unitsgroupId = this.unitsgroups.length
          ? this.unitsgroups[0].id
          : ''
      } else {
        if (this.mailing && this.mailing.units_group) {
          this.unitsgroupId = this.mailing.units_group.id
        } else {
          // Handle the case where units_group is not defined.
          // For example, set a default value or log an error.
          this.unitsgroupId = '' // or some default value
          console.log('error handler')
        }
        this.unitOrUnitsgroup = 'group'
        this.unitId = this.units.length ? this.units[0].id : ''
      }
      this.reportTemplateId = this.mailing.report_template.id
    }
    this.dateFormat = this.me.active_company.dateformat.key
    this.timeFormat = this.me.active_company.timeformat.key
    this.dateTimeFormat = `${this.dateFormat} ${this.timeFormat}`
    const filters = JSON.parse(
      localStorage.getItem(`filtredUnits_${this.me.active_company.id}`)
    )

    if (filters && filters.units && filters.units.length) {
      this.customUnits = filters.units
      this.unitId = this.customUnits[0].id
      this.isOnlyUnits = false
      if (filters.units.length === this.units.length) {
        this.currentUnits = this.units
        this.isOnlyUnits = false
      } else {
        this.currentUnits = filters.units
        this.isOnlyUnits = true
      }
    } else {
      this.currentUnits = this.units
      this.isOnlyUnits = false
    }
    if (this.title === 2) {
      this.currentUnits = this.units
      this.showAllUnits = true
    }
  }
}
</script>
<style scoped>
.email-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.email-name {
  width: calc(100% - 24px);
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
}
.email-icon {
  float: right;
}
.slide-fade-enter-active {
  transition: all 0.4s ease;
}

.slide-fade-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
</style>
